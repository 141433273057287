import "./Contact.css"
import ContactHeader from "./ContactSecties/ContactHeader"
import ContactForm from "./ContactSecties/ContactForm"

const Contact = () => {
    return ( 
        <section className="contact__section" id="Contact">
            {/* <ContactHeader/> */}
            <ContactForm/>
           
        </section>
    );
}
 
export default Contact;