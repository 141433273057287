import Navigation from "../components/Home/Navigation/Navigation"
import Header from "../components/Home/Header/Header"
import About from "../components/Home/About/About"
import Skills from "../components/Home/Skills/Skills"
import Projects from "../components/Home/Projects/Projects"
import Contact from "../components/Home/Contact/Contact"
import Footer from "../components/Home/Footer/Footer"


const homepage = (props) => {
    return(
        <>
            <Navigation fixed="fixed"
            />
            <Header 
                titelH1="Thies van der Zon"
                // titelH2="Enthousiaste en leergierige software developer"
                titelH3="Welkom op mijn portfolio"
                imageH2=""
            />
            <About 
                naam="Thies van der Zon"
                leeftijd="18"
                opleiding="Software development"

               
            />
            <Skills />
            <Projects projectId={props.projectId}
                      projectHandler={props.projectHandler}
            />
            {/* <Contact /> */}
            <Footer/>
        </>
    )
}

export default homepage