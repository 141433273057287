import Collezione from "../../../../images/Projects/Collezione.jpeg";
import Huisweid from "../../../../images/Projects/huisweid_logo_bg_gradient.jpeg";
import TEST from "../../../../images/Projects/coming.webp";



const ProjectData = [
    {
        image: Collezione,
        
        href_globe: "http://32048.hosts1.ma-cloud.nl",
        href_git: "https://github.com/Thiesvdz/Collizione-Webapp",
        
        icon_color: "Sch",
        
        link: "/collezione",
        category: "School",
        
        title: "Collezione",
        desc_project: "Collezione is een interactieve collectie website waarbij gebruikers een "+
                      "collectie kunnen bijhouden en aanpassen. De website heeft een aantal leuke  " +
                      "functies zoals: Login, Backlog chooser, Collectie aanpassen/updaten en afgeschermde routes. "+
                      "Dit project was toegewezen als 'beroepsopdracht' door school. ",

        desc_tech: "De website heb ik doormiddel van HTML, CSS, SCSS en REACT. Met HTML en CSS had al heel wat ervaring, " +
        "maar dit was het eerste project waar ik SCSS en REACT grootschalig gebruikte. SCSS had ik snel onder de knie, alleen " +
        "REACT was wat lastiger. Dit kwam grotendeels omdat ik niet al te veel javascript kennis had. Vanaf ≈ half september " + 
        "begon ik met het leren van REACT, dus ik ben erg blij met de progressie die ik heb gemaakt in een relatief korte tijd"
        ,
        desc_team: "",
        date: "07-02-2023",
        
  

        html: "fa-brands fa-html5",
        css: "fa-brands fa-css3-alt",
        scss: "fa-brands fa-sass",
        react: "fa-brands fa-react",
        js: "fa-brands fa-js",

        id: 1
    },
    {
        image: Huisweid,

        title: "Huisweid Festival",
        desc: "",
        date: "13-04-2023 t/m 25-05-2023",

        href_globe: "https://huisweidfestival.nl",
        href_linkedin: "https://www.linkedin.com/feed/update/urn:li:activity:7069605905343868928/",
      
        icon_color: "Sta",

        desc_project: "Huisweid is een festival dat geld verzameld voor meerdere goede doelen. Mijn huidige stage bedrijf (studioviv feb 2023 - jul 2023) " + 
        "heeft huisweid al meerdere jaren als klant. Dit jaar kwam daarom dus weer de aanvraag voor een website, alleen dit jaar kwam er wat extra's bij kijken. " + 
        "Vanuit studioviv was bedacht om de een dynamisch line up component te maken met functionaliteiten zoals: Filteren en pop-ups. " + 
        "Uiteindelijk kwam het idee om een bodem navigatie te gebruiken op tafel, hierdoor werd het een soort gesimuleerde webapp " + 
        "Ik raad sterk aan om een kijkje te nemen naar de site, vooral op de mobiele versie. " +
        "De website is na het festival ongeveer 33.000 keer bezocht, met een bezoekerspiek van 10.000 op het festival zelf. " + 
        "Check onderaan mijn linkedin bericht voor meer info!" , 

        
        desc_tech: "Ik heb een een schets gemaakt van de mobile versie in adobe XD en die nagemaakt in een wordpress omgeving. " + 
        "Daarna is het designen overgenomen door een interne designer bij het bedrijf, zodat ik verder kon focussen om de site. " +
        "De filter functie gaat doormiddel van PHP en JQUERY, PHP om te sorteren en JQUERY om click events te handlen " + 
        "Daarnaast is er nog een map functie waarbij ik een plugin gebruik om er dynamische markers op te tonen. " + 
        "",
        // link: "/project",
        link: "/huisweid",
        category: "Stage",

        html: "fa-brands fa-html5",
        css: "fa-brands fa-css3-alt",
        // scss: "fa-brands fa-sass",
        js: "fa-brands fa-js",
        php: "fa-brands fa-php",
        bootstrap: "fa-brands fa-bootstrap",
        wordpress: "fa-brands fa-wordpress",

        id: 2

    },
    {
        image: TEST,

        title: "",
        desc: "",
        date: "",
        
        href_globe: "",
        href_trello: "",
        href_git: "",

        desc_project: "",
        desc_tech: "",
        desc_team: "",

        icon_color: "Per",


        link: "/project",
        category: "Persoonlijk",

        id: 3

    },
   
]

export default ProjectData