const AboutP = [
    {
        icon: "Linkdin",
        className: "fa-brands fa-linkedin about__text__i", 
        href:"https://www.linkedin.com/in/thies-van-der-zon-05274b224/",
        id: 0
    },
    {
        icon: "Github",
        className: "fa-brands fa-square-github about__text__i", 
        href:"https://github.com/Thiesvdz",
        id: 1
    },
    {
        icon: "Mail",
        className: "fa-solid fa-envelope about__text__i", 
        href:"mailto: thiesvanderzon@gmail.com",
        id: 2
    },
    
    {
        icon: "Share",
        className: "fa-sharp fa-solid fa-share about__text__i", 
        href:"",
        id: 3
    },
  
]

export default AboutP