
const SkillsData = [
    {
        className: "Skills_icons fa-brands fa-html5",
        title: "HTML",
        description: "Ik gebruik nu ongeveer 2,5 jaar html en ben vergeleken met het begin een heel stuk verbeterd.",
        classNameHeader: "HTML__head",
        classNameFooter: "HTML__foot",
        id: 0,
    },
    {
        className: "Skills_icons fa-brands fa-css3-alt", 
        title: "CSS",
        description: "Net als html gebruik css al ongeveer 2,5 jaar, ik vindt het altijd erg leuk op nieuw style technieken uit te proberen om mijn werk te optimaliseren.",
        classNameHeader: "CSS__head",
        classNameFooter: "CSS__foot",
        id: 1,
    },
    {
        className: "Skills_icons fa-brands fa-sass",
        title: "SCSS",
        description: "Ik ben gebruik nu een aantal maanden SCSS, deze portfolio heb ik ook met SCSS gebouwt om te oefenen.",
        classNameHeader: "SCSS__head",
        classNameFooter: "SCSS__foot",
        id: 2,
    },
    {
        className: "Skills_icons fa-brands fa-bootstrap",
        title: "BOOTSTRAP",
        description: "Sinds mijn stage ben ik begonnen met het leren van bootstrap, voor zover bevalt het mij best wel.",
        classNameHeader: "BOOTSTRAP__head",
        classNameFooter: "BOOTSTRAP__foot",
        id: 3,
    },
    {
        className: "Skills_icons fa-brands fa-js", 
        title: "JAVASCRIPT",
        description: "Ik heb redelijk wat javascript kennis, grotendeels doormiddel van react",
        classNameHeader: "JS__head",
        classNameFooter: "JS__foot",
        id: 4,
    },
    {
        className: "Skills_icons fa-brands fa-react", 
        title: "REACT",
        description: "Ik heb best wel wat basis kennis met react, deze portfolio heb ik ook gebouwt met react",
        classNameHeader: "REACT__head",
        classNameFooter: "REACT__foot",
        id: 5,
    },
    {
        className: "Skills_icons fa-brands fa-laravel", 
        title: "LARAVEL",
        description: "Ik heb 2 periodes van 8 weken met laravel gewerkt. Toen beviel het mij wel, alleen nu ik react aan het leren ben gaat mijn voorkeur naar react",
        classNameHeader: "LARAVEL__head",
        classNameFooter: "LARAVEL__foot",
        id: 6,
    },
    {
        className: "Skills_icons fa-brands fa-php",
        title: "PHP",
        description: "Sinds mijn stage begon zijn mijn php skills stevig verbeterd, neem een kijkje hieronder naar mijn stage projecten om meer te lezen",
        classNameHeader: "PHP__head",
        classNameFooter: "PHP__foot",
        id: 7,
    },
    {
        className: "Skills_icons fa-brands fa-wordpress", 
        title: "WORDPRESS",
        description: "Net als php ben ik sinds mijn stage een heel stuk beter geworden in wordpress.",
        classNameHeader: "WORDPRESS__head",
        classNameFooter: "WORDPRESS__foot",
        id: 8,
    },
]

export default SkillsData