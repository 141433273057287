import "./Header.css";
import ProfileImage from "../../../images/Profile-image/FotoThies.jpg";
import { motion } from "framer-motion";

const Header = (props) => {
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.12, delayChildren: 0.04 * i },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 120,
      },
    },
    hidden: {
      opacity: 0,
      y: 150,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };
  return (
    <>
    <header
      className="header"
      id="Header"
    >
          <motion.div className="header__intro__text"
          variants={container}
          initial="hidden"
          whileInView="visible"
          >
            <motion.div variants={child} className="border-top" ></motion.div>
            <motion.div variants={child} className="border-bottom"  ></motion.div>
            <div className="a">
              <motion.h3 variants={child} className="header__h3">
                {props.titelH3 || "Placeholder"}
              </motion.h3>
              <motion.h1 variants={child} className="header__h1">
                <span className="Thies">Thies</span> van der Zon
              </motion.h1>
              <motion.h2 variants={child} className="header__h2">
                <span className="green__text">Enthousiaste</span> en <span className="green__text">leergierige</span> software developer
              </motion.h2>
            </div>
          </motion.div>
    </header>
</>
  );
};

export default Header;
