const Program = [
    {
        icon: "html",
        className: "Pro_icons fa-brands fa-html5", 
        id: 0
    },
    {
        icon: "css",
        className: "Pro_icons fa-brands fa-css3-alt", 
        id: 1
    },
    {
        icon: "sass",
        className: "Pro_icons fa-brands fa-sass", 
        id: 2
    },
    {
        icon: "bootstrap",
        className: "Pro_icons fa-brands fa-bootstrap", 
        id: 3
    },
    {
        icon: "javascript",
        className: "Pro_icons fa-brands fa-js", 
        id: 4
    },
    {
        icon: "react",
        className: "Pro_icons fa-brands fa-react", 
        id: 5
    },
    {
        icon: "laravel",
        className: "Pro_icons fa-brands fa-laravel", 
        id: 6
    },
    {
        icon: "php",
        className: "Pro_icons fa-brands fa-php", 
        id: 7
    },
    {
        icon: "wordpress",
        className: "Pro_icons fa-brands fa-wordpress", 
        id: 8
    },
]

export default Program