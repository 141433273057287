import "./Footer.css";
import icons from "../About/Data/AboutIcons";
import RenderIcons from "../About/Data/RenderIcons";
import Contact from "../Contact/Contact";
const Footer = () => {
  return (
    <footer className="FOOTER">
      <div className="icon_container">
        <h3>
          <span className="Thies">Thies</span> van der Zon
        </h3>
        <RenderIcons />
      </div>
      <Contact />
      {/* <p>© Thies van der Zon. All Rights Reserved</p> */}
    </footer>
  );
};

export default Footer;
