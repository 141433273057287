import "./App.css";
import Homepage from "../pages/homepage";
// import RekenProject from "../components/ProjectPages/RekenProject/RekenProject"
import Projectpage from "../pages/projectpage"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";
const App = () => {
  const [projectId, setProjectId] = useState(null);

  function projectHandler(status) {
    setProjectId(status);
  }

  return (
    <Router>
      <Switch>
        <Route path="/projectpage">
          <Projectpage incomingProjectId={projectId}/>
        </Route>
        <Route exact path="/">
          <Homepage projectId={projectId} projectHandler={projectHandler}/>
        </Route>
      </Switch>
   </Router>
  );
};

export default App;