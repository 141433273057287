import "./Template.css";
import Data from "../../Home/Projects/ProjectData/ProjectData";
import { useState, useEffect } from "react";



const Huisweid = (props) => {
  const [project, SetProject] = useState(Data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const filtered = project.filter((project_D) => {
    return 2 === parseInt(project_D.id);
  });

  return (  
    <main className="Template__container">
      {filtered.map((projectData) => {
        let team = "";
         if(projectData.desc_team){
            team = <div className="Template__article__wrapper">
                    <h2><span>Teamgenoten</span></h2>
                    <p>{projectData.desc}</p>
                  </div>;
          } 
    
          var [html,css,scss,js,php,bootstrap,react,wordpress] = ["","","","","","","",""];
          var [globe,git,trello,linkedin] = ["","","",""];

          if(projectData.html){
            html = <li className="Html"><i className={projectData.html}></i></li>;
          }if(projectData.css){
            css = <li className="Css"><i className={projectData.css}></i></li>;
          }
          if(projectData.js){
            js = <li className="Js"> <i className={projectData.js}></i> </li>;
          }
          if(projectData.php){
            php = <li className="Php"> <i className={projectData.php}></i> </li>;
          }
          if(projectData.bootstrap){
            bootstrap = <li className="Bootstrap"><i className={projectData.bootstrap}></i></li>;
          }
          if(projectData.react){
            react = <li className="React"><i className={projectData.react}></i></li>;
          }
          if(projectData.scss){
            scss = <li className="Scss"><i className={projectData.scss}></i></li>;
          }
          if(projectData.wordpress){
            wordpress = <li className="Wordpress"><i className={projectData.wordpress}></i></li>;
          }

          if(projectData.href_globe){
            globe = <a href={projectData.href_globe} target="__blank"><i className={"fa-solid fa-globe " + projectData.icon_color}></i></a>
          }
          if(projectData.href_git){
            git = <a href={projectData.href_git} target="__blank"><i className={"fa-brands fa-github " + projectData.icon_color}></i></a>
          }
          if(projectData.href_trello){
            trello = <a href={projectData.href_trello} target="__blank"><i className={"fa-brands fa-trello " + projectData.icon_color}></i></a>
          }
          if(projectData.href_linkedin){
            linkedin = <a href={projectData.href_linkedin} target="__blank"><i className={"fa-brands fa-linkedin " + projectData.icon_color}></i></a>
          }
          
          

        return ( 
          <>
            <header className="Template__header" key={projectData.id}>
              <div style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1.5)), url(${projectData.image})`}} className="Template__background">
                <div className="Template__header__text">
                  <h1 className={"Template__header_title " + projectData.icon_color}>{projectData.title}</h1>
                  <span className="Template__header_title">{projectData.date}</span>
                  <ul className="Template__header__ul">
                    {html}{css}{bootstrap}{js}{php}{scss}{react}{wordpress} 
                  </ul>
                </div>
              </div>
            </header>
            <section className="Template__section__wrapper custom_container">
              <div className="Template__article__wrapper">
                <h2><span>Het Project</span></h2>
                <p>{projectData.desc_project}</p>
              </div>
              <div className="Template__article__wrapper">
                <h2><span>Gebruikte technieken</span></h2>
                <p>{projectData.desc_tech}</p>
              </div>
              {team}
              <div className="Template__article__wrapper">
                <h2><span>Relevante links</span></h2>
                <div className="Template__link__wrapper">
                    {globe}
                    {trello}
                    {git}
                    {linkedin}
                </div>
              </div>
            </section>
          </>
        );
      })}
    </main>
  );
};

export default Huisweid;
