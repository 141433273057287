import "./Navigation.css";
import logoTZ from "../../../images/Logo/LogoTZ.svg";
import NavigationData from "./NavigationData/NavigationData";
import {useState} from "react";
import {Link} from "react-router-dom";


const Navigation = (props) => {
const [navClick, setnavClick] = useState(false);

const menuClick = () => {
    setnavClick(!navClick);
}
const navItemClicked = (e) => {
  setnavClick(false)
}
  let NavigationFromData = NavigationData.map((item,id) => {
    return (
        <a href={item.url} key={id} className="navbar__a">
          <li className={item.className}  onClick={navItemClicked}>
             {item.title} <i className={item.icon + " icons"}></i>
          </li>
        </a>
    );
  });

  return (
    <nav className={"nav " + props.fixed}>
      <figure className="nav__logo__container">
        {/* <img src={logoTZ} alt="" className="nav__logo" /> */}
        <a href="/#Header" id="header_text">
          <h3 className="nav__h3"><span className="Thies">Thies</span> van der Zon</h3>
        </a>
      </figure>
      <div className="nav__small">
          <i className={navClick ? "fa-solid fa-times" : "fa-solid fa-bars" + " dropdown"} onClick={menuClick}></i>
      </div>
      <ul className={navClick ? "nav__ul active": "nav__ul"}>{NavigationFromData}</ul>
    </nav>
  );
};

export default Navigation;
