import Data from "./ProjectData/ProjectData";
import "./Projects.css"
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {Link} from "react-router-dom";

const Projects = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [item, setItem] = useState(Data);
  const menuItems = [...new Set(Data.map((Val) => Val.category))];

  const filterItem = (curcat) => {
    setIsActive(current => !current);
    const newItem = Data.filter((newVal) => {
      return newVal.category === curcat; 
    });
    setItem(newItem);
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      rotate: 0,
      scale: 1,
      transition:{ type: "spring", stiffness: 75 }
    },
    hidden: {
      opacity: 0,
      y: 50,
      rotate: 180,
      scale: .25,
      transition: {
        transition:{ type: "spring", stiffness: 75 }
      },
    },
  };

  
  let projectCardToBeRenderd = item.map((data) => {
    const projectIdHandler = () => {
      props.projectHandler(data.id);
    }

    var [globe,git,trello] = ["","",""];

    if(data.href_globe){
      globe = <a href={data.href_globe} target="__blank"><i className={"fa-solid fa-globe " + data.icon_color}></i></a>
    }
    if(data.href_git){
      git = <a href={data.href_git} target="__blank"><i className={"fa-brands fa-github " + data.icon_color}></i></a>
    }
    if(data.href_trello){
      trello = <a href={data.href_trello} target="__blank"><i className={"fa-brands fa-trello " + data.href_trello}></i></a>
    }
    return (
        <motion.article 
        className={"project__article"} key={data.id} 
        variants={child}
        initial="hidden"
        whileInView="visible"
        whileHover={{ scale: 1.03 }}
        >
          <div class="container" data-ribbon={data.category}></div>
          <header className={data.category + " project__header"}>
            <figure className="project__figure">
              <Link to={"/projectpage" + data.link} onClick={projectIdHandler}><img src={data.image} alt="" className="project__image" /></Link>
            </figure>
          </header>
          <footer className="project__footer">
            <ul className="project__ul">
            {globe}
                    {trello}
                    {git}
            </ul>
          </footer>
        </motion.article>
    );
  });

  return <section className="project__section" id="Projecten">
    <div className="filterbar_wrapper">
      <ul className="filterbar_ul">
        <button
          className="all"
          onClick={() => setItem(Data)}
        >
          Alle
        </button>
        {menuItems.map((Val, id) => {
          return (
            <button
              key={id}
              onClick={()=>filterItem(Val)}
              className={Val}
            >
              {Val}
            </button>
          );
          })}

      </ul>
    </div>
    <div className="project__wrapper">
      {projectCardToBeRenderd}
      {/* <Card/> */}
    </div>
  </section>;

};

export default Projects;
