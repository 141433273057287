import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import ProjectTemplate from "../components/ProjectPages/ProjectTemplate/Template";
import Navbar from "../components/Home/Navigation/Navigation"
// import Footer from "../components/ProjectPages/ProjectTemplate/Footer/Footer";

import Huisweid from "../components/ProjectPages/ProjectTemplate/huisweid";
import Collezione from "../components/ProjectPages/ProjectTemplate/collezione";
// import Footer from "../components/ProjectPages/RekenProject/Footer/Footer";
// import EyeProject from "../components/ProjectPages/Eye/EyeProject";
// import Duurzaamhuis from "../components/ProjectPages/DuurzaamHuis/DuurzaamHuisProject"
// import ClipNDip from "../components/ProjectPages/ClipNDip/ClipNDipProject"

const ProjectPage = (props) => {  
let { path } = useRouteMatch();
return (
    <>
      <Switch>
        <Route path={`${path}/project`}>
            <Navbar fixed="relative"/>
            <ProjectTemplate  projectId={props.incomingProjectId}
            />
        </Route>
        <Route path={`${path}/huisweid`}>
            <Navbar fixed="relative"/>
            <Huisweid  projectId={props.incomingProjectId}
            />
        </Route>
        <Route path={`${path}/collezione`}>
            <Navbar fixed="relative"/>
            <Collezione  projectId={props.incomingProjectId}
            />
        </Route>
      </Switch>
    
    </>
  );
};

export default ProjectPage;
