import "./Skills.css"
import SkillsCard from "./SkillsCard/SkillsCard"

const Skills = () => {

    

    return(
        <section className="section__skills" id="Skills">
            <SkillsCard />
        </section>
    )
}

export default Skills