const ContactHeader = () => {
  return (
    <form action="https://formsubmit.co/e372d7bd42096d52816ea1a9caae9c7a" method="POST" className="contact__form">
     <div className="a">
      <div className="row">
        <div className="input__wrapper">
          <input type="text" id="name" className="input row" name="naam" required />
          <label htmlFor="name">
            <i className="fa-regular fa-user"></i> Naam
          </label>
        </div>
        <div className="input__wrapper">
          <input type="text" id="lastName" className="input row" name="achternaam" required />
          <label htmlFor="lastName">
            <i className="fa-regular fa-user"></i> Achternaam
          </label>
        </div>

        <div className="input__wrapper">
          <input type="text" id="email" className="input" name="email" required />
          <label htmlFor="email">
            <i className="fa-regular fa-envelope"></i> Email
          </label>
        </div>
      </div>

      <div className="input__wrapper bericht__wrapper">
        <textarea
          name="bericht"
          id="bericht"
          className="textarea"
          rows="8"
          required
        ></textarea>
        <label htmlFor="bericht" className="bericht">
          <i className="fa-regular fa-comments"></i> Bericht
        </label>
      </div>
      </div>
      <div className="btn__wrapper">
        <button  type="submit" name="submit" className="contact__btn">verstuur bericht</button>
      </div>
    </form>
  );
};

export default ContactHeader;
